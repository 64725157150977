@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@700&family=Work+Sans:wght@300;400;500&display=swap");
.content-header {
  font-family: "JetBrains Mono", monospace;
  font-weight: 500;
  font-size: 2.5rem;
  line-height: 2.5rem;
}

.my-container {
  margin-top: 7.5rem;
  width: 100%;
}

.container {
  padding: 0 7.5rem;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.clip-box {
  overflow: clip;
}

.error-box {
  position: absolute;
  text-align: center;
  min-height: 3.5rem;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(255, 0, 0, 0.7);
  border-radius: 8px;
  color: white;
  font-family: "Work Sans", sans-serif;
  font-weight: 500;
}

.m-1 {
  margin: 0.5rem;
}

.p-1 {
  padding: 0.5rem;
}

.mt-1 {
  margin-top: 0.5rem;
}

.pt-1 {
  padding-top: 0.5rem;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.pb-1 {
  padding-bottom: 0.5rem;
}

.mr-1 {
  margin-right: 0.5rem;
}

.pr-1 {
  padding-right: 0.5rem;
}

.ml-1 {
  margin-left: 0.5rem;
}

.pl-1 {
  padding-left: 0.5rem;
}

.m-2 {
  margin: 1rem;
}

.p-2 {
  padding: 1rem;
}

.mt-2 {
  margin-top: 1rem;
}

.pt-2 {
  padding-top: 1rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.pb-2 {
  padding-bottom: 1rem;
}

.mr-2 {
  margin-right: 1rem;
}

.pr-2 {
  padding-right: 1rem;
}

.ml-2 {
  margin-left: 1rem;
}

.pl-2 {
  padding-left: 1rem;
}

.m-3 {
  margin: 1.5rem;
}

.p-3 {
  padding: 1.5rem;
}

.mt-3 {
  margin-top: 1.5rem;
}

.pt-3 {
  padding-top: 1.5rem;
}

.mb-3 {
  margin-bottom: 1.5rem;
}

.pb-3 {
  padding-bottom: 1.5rem;
}

.mr-3 {
  margin-right: 1.5rem;
}

.pr-3 {
  padding-right: 1.5rem;
}

.ml-3 {
  margin-left: 1.5rem;
}

.pl-3 {
  padding-left: 1.5rem;
}

.m-4 {
  margin: 2rem;
}

.p-4 {
  padding: 2rem;
}

.mt-4 {
  margin-top: 2rem;
}

.pt-4 {
  padding-top: 2rem;
}

.mb-4 {
  margin-bottom: 2rem;
}

.pb-4 {
  padding-bottom: 2rem;
}

.mr-4 {
  margin-right: 2rem;
}

.pr-4 {
  padding-right: 2rem;
}

.ml-4 {
  margin-left: 2rem;
}

.pl-4 {
  padding-left: 2rem;
}

.m-5 {
  margin: 2.5rem;
}

.p-5 {
  padding: 2.5rem;
}

.mt-5 {
  margin-top: 2.5rem;
}

.pt-5 {
  padding-top: 2.5rem;
}

.mb-5 {
  margin-bottom: 2.5rem;
}

.pb-5 {
  padding-bottom: 2.5rem;
}

.mr-5 {
  margin-right: 2.5rem;
}

.pr-5 {
  padding-right: 2.5rem;
}

.ml-5 {
  margin-left: 2.5rem;
}

.pl-5 {
  padding-left: 2.5rem;
}

.scrollbar, .testimonial-container .testimonial-box {
  margin: 0 auto;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.btn {
  width: 100%;
  height: 3.375rem;
  font-family: "Work Sans", sans-serif;
  color: white;
  font-size: 1.1rem;
  border-radius: 8px;
  outline: none;
  border: none;
  background-color: #CEAE78;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.btn:hover {
  background: #E6C48E;
}

.btn:active {
  background: #CEAE78;
}

.btn-next {
  height: 3.375rem;
  width: 3.375rem;
  z-index: 2;
  top: calc(50% - 1.6875rem);
  position: absolute;
}

.btn-right {
  right: 0;
  -webkit-transform: translate(50%);
          transform: translate(50%);
}

.btn-left {
  -webkit-transform: translate(-125%);
          transform: translate(-125%);
}

.btn-wh-scroll {
  position: absolute;
  bottom: 0;
  left: calc(50% - 7.5rem);
  width: 15rem;
  margin: 0 auto 1rem auto;
  background-color: rgba(34, 34, 34, 0.2);
}
.btn-wh-scroll:hover {
  background-color: rgba(230, 196, 142, 0.8);
}

.active, .focus-btn:hover {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
  background-color: #CEAE78;
}

.focus-btn-container {
  margin: 0.5rem auto;
  text-align: center;
}

.focus-btn {
  cursor: pointer;
  border: none;
  margin: 0 0.5rem;
  padding: 0.5rem;
  height: 1rem;
  width: 1rem;
  text-align: center;
  border-radius: 50%;
  background-color: #CEAE78;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.btn-more {
  pointer-events: all;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  margin-top: 2rem;
  margin-left: calc(50% - 15px);
}

.underline-big {
  height: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.underline-big div:nth-child(1) {
  width: 72px;
}
.underline-big div:nth-child(2) {
  width: 32px;
}
.underline-big div:nth-child(3) {
  width: 6px;
}

.underline-medium {
  height: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
.underline-medium div:nth-child(1) {
  width: 2.5rem;
}
.underline-medium div:nth-child(2) {
  width: 1.1rem;
}
.underline-medium div:nth-child(3) {
  width: 0.25rem;
}

.underline-line {
  height: inherit;
  margin-right: 4px;
  background-color: #CEAE78;
  border-radius: 10px;
}

.hero-container, .bg, .bg-img, .bg-video {
  height: 100vh;
  width: 100vw;
  position: relative;
}

.bg, .bg-img, .bg-video {
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
  z-index: -1;
}

.bg-video {
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
  z-index: -1;
}

.bg-img {
  display: none;
}

.header-logo {
  margin-top: 4rem;
  position: absolute;
  width: 25%;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

@media screen and (max-width: 700px) {
  .header-logo {
    width: 50%;
  }
}
.hero-about {
  position: relative;
  width: 100%;
  color: #f3f3f3;
}
.hero-about h1 {
  font-family: "JetBrains Mono", monospace;
  font-size: 4rem;
  line-height: 4rem;
}
.hero-about p {
  font-family: "Work Sans", sans-serif;
  font-weight: 300;
}
.hero-about ul {
  display: none;
}

.audio-player-card {
  margin-right: 8px;
  width: 21.5em;
  height: 11rem;
  background: #f4f4f4;
  -webkit-box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}
.audio-player-card img {
  width: 8rem;
  height: 8rem;
  border-radius: 9px;
}

.flex-audio-player {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.audio-player {
  position: relative;
  padding-left: 0.8rem;
  width: 70%;
  height: 8rem;
}
.audio-player .audio-button {
  color: #CEAE78;
  text-align: center;
  margin-bottom: 0.8rem;
  font-size: 1.4rem;
}
.audio-player .artist-name {
  font-family: "Work Sans", sans-serif;
  color: #7A7A7A;
  font-weight: 300;
}
.audio-player .song-name {
  font-family: "Work Sans", sans-serif;
  color: #222222;
  font-weight: 500;
}
.audio-player .audio-inputs {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-right: 0.5rem;
}

.tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  white-space: nowrap;
  position: relative;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
}
.tags .tag {
  margin-right: 0.25rem;
  color: white;
  background-color: #222222;
  padding: 0.5rem;
  border-radius: 9px;
  -webkit-transform: translateY(25%);
          transform: translateY(25%);
  height: 2rem;
}

.slider-container {
  --progress-bar-height: 0.25rem;
}

.slider-container {
  position: relative;
  width: 100%;
}

/* Background */
.slider-container::before {
  content: "";
  background-color: #D0D0D0;
  width: 99%;
  height: calc(var(--progress-bar-height) - 1px);
  display: block;
  position: absolute;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  pointer-events: none;
}

/* Custom Progress Bar */
.progress-bar-cover {
  background-color: #CEAE78;
  width: 0%;
  height: var(--progress-bar-height);
  display: block;
  position: absolute;
  border-radius: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 1;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: none;
}

/*  Hide Original */
.range {
  -webkit-appearance: none;
  height: 10px;
  width: 100%;
  cursor: pointer;
  opacity: 0;
  margin: 0 auto;
}

.slider-container {
  --thumb-width: 1rem;
  --thumb-height: 1rem;
}

.range::-webkit-slider-thumb {
  width: var(--thumb-width);
  height: var(--thumb-height);
  background: #350f2d;
  border: 0.7px solid #000000;
  border-radius: 50%;
  cursor: pointer;
  -webkit-appearance: none;
}

.thumb {
  width: var(--thumb-width);
  height: var(--thumb-height);
  z-index: 3;
  background: black;
  position: absolute;
  border: 0.25rem solid #CEAE78;
  border-radius: 50%;
  top: 50%;
  -webkit-transform: translate(0%, -50%);
          transform: translate(0%, -50%);
  pointer-events: none; /* Remove pointer events on thumb so user can click on the actual thumb beaneath it!  */
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; /*  Prevent Accidentally highlighting the number while sliding the cursor  */
}

.references-container p {
  width: 30rem;
  margin-bottom: 2.5rem;
  font-family: "Work Sans", sans-serif;
  font-weight: 300;
}

.contact-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.contact-container .contact-box .contact-img {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 50rem;
  min-width: 45rem;
  margin: 0 1rem;
  border-radius: 20px;
  right: 0;
}
.contact-container .contact-box .decor-border {
  border: 2px solid rgba(255, 102, 68, 0.24);
  border-radius: 20px;
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 45rem;
  max-width: 50rem;
  bottom: 90%;
  left: 5rem;
  z-index: -1;
}

.label-before, .field input:focus + label::before, .field input:valid + label::before {
  font-family: "Work Sans", sans-serif;
  font-weight: 300;
  line-height: 1.5rem;
  top: -50%;
  font-size: 12px;
  padding-left: 6px;
  left: -0.5rem;
}

.box-styling, .field-textarea:focus-within, .field-textarea, .field:focus-within, .field {
  border: #222222 1px solid;
  border-radius: 0.5rem;
}

.field {
  position: relative;
  height: 3.375rem;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.field:focus-within {
  border-color: #CEAE78;
}
.field:hover {
  border-color: #E6C48E;
}
.field label::before {
  content: attr(title);
  position: absolute;
  top: 25%;
  left: 0.5rem;
  font-family: "Work Sans", sans-serif;
  font-weight: 300;
  line-height: 1.5rem;
  font-size: 1rem;
  color: #777;
  -webkit-transition: 300ms all;
  transition: 300ms all;
}
.field input {
  width: calc(100% - 1rem);
  height: calc(100% - 1rem);
  border: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: #F6F0EA;
  font-size: 1rem;
  color: #222222;
  cursor: pointer;
  font-family: "Work Sans", sans-serif;
}
.field input:focus {
  outline: 0;
  border-color: #CEAE78;
}
.field input:valid + label::before {
  content: attr(title);
}
.label-before-textarea, .field-textarea textarea:focus + label::before, .field-textarea textarea:valid + label::before {
  font-family: "Work Sans", sans-serif;
  font-weight: 300;
  line-height: 1.5rem;
  top: -20%;
  font-size: 12px;
  padding-left: 6px;
  left: -0.5rem;
}

.field-textarea {
  margin: 1.75rem 0;
  position: relative;
  height: 9.375rem;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.field-textarea:focus-within {
  border-color: #CEAE78;
}
.field-textarea:hover {
  border-color: #E6C48E;
}
.field-textarea label::before {
  content: attr(title);
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  font-family: "Work Sans", sans-serif;
  font-weight: 300;
  line-height: 1.5rem;
  font-size: 1rem;
  color: #777;
  -webkit-transition: 300ms all;
  transition: 300ms all;
}
.field-textarea textarea {
  width: calc(100% - 1rem);
  height: calc(100% - 1rem);
  resize: none;
  border: none;
  background-color: #F6F0EA;
  font-size: 1rem;
  color: #222222;
  font-family: "Work Sans", sans-serif;
  cursor: pointer;
}
.field-textarea textarea:focus {
  outline: 0;
  border-color: #CEAE78;
}
.field-textarea textarea:valid + label::before {
  content: attr(title);
}
.contact-card {
  height: 32rem;
  width: 20rem;
  min-width: 15rem;
}
.contact-card h2, .contact-card p {
  width: 15rem;
}

.testimony-card {
  position: relative;
  padding: 2.5rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  background: #FFFFFF;
  height: 100%;
  width: 30rem;
  -webkit-box-shadow: 0px 10px 40px -17px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 10px 40px -17px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.testimony-card:hover {
  z-index: 10;
  -webkit-transform: translate(-5px, -5px);
          transform: translate(-5px, -5px);
  border-radius: 20px;
  -webkit-box-shadow: 20px 30px 20px -17px rgba(0, 0, 0, 0.5);
          box-shadow: 20px 30px 20px -17px rgba(0, 0, 0, 0.5);
}
.testimony-card .testimony-box div .testimony-text {
  text-align: justify;
  font-style: italic;
}
.testimony-card .testimony-box div img {
  display: block;
  float: right;
  height: 5rem;
  margin-left: 1rem;
  border-radius: 10px;
}
.testimony-card .testimony-box div p {
  font-size: 1rem;
}
.testimony-card .testimony-box .testimony-name {
  display: block;
  font-weight: 300;
  font-size: 13px;
  color: #CEAE78;
  text-align: end;
}
.testimony-card .testimony-moblie-card {
  background-color: white;
  height: 100%;
  width: 100%;
}

:root {
  --animation: 20s;
}

.testimonial-container {
  width: 50%;
}
.testimonial-container h3, .testimonial-container .underline-medium {
  padding-left: 2rem;
}
.testimonial-container .testimonial-box {
  overflow-y: scroll;
  -ms-scroll-chaining: none;
      overscroll-behavior: contain;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  padding-top: 2rem;
  padding-right: 2rem;
  height: 100%;
}
.testimonial-container .testimonial-box::-webkit-scrollbar {
  display: none;
}
.testimonial-container .testimonial-box .testimonial-inner-box {
  margin: 0 auto;
  padding-left: 2.5rem;
}
.testimonial-container .testimonial-box .testimonial-inner-box:hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

@-webkit-keyframes translateDown {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  100% {
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
}

@keyframes translateDown {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  100% {
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
}
@-webkit-keyframes translateUp {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  100% {
    -webkit-transform: translateY(200%);
            transform: translateY(200%);
  }
}
@keyframes translateUp {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  100% {
    -webkit-transform: translateY(200%);
            transform: translateY(200%);
  }
}
.testimony-gallery {
  position: relative;
  width: 50rem;
  margin: 0;
  padding: 0 3rem;
  overflow: clip;
}
.testimony-gallery::before {
  content: "";
  pointer-events: none;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  background: -webkit-gradient(linear, left top, right top, from(#f6f0ea), color-stop(8%, rgba(246, 240, 234, 0)), color-stop(90%, rgba(246, 240, 234, 0)), to(#f6f0ea));
  background: linear-gradient(90deg, #f6f0ea 0%, rgba(246, 240, 234, 0) 8%, rgba(246, 240, 234, 0) 90%, #f6f0ea 100%);
}

.inner-gallery-box, .testimoal-gallery-moblie-inner-box {
  margin: 0 auto;
  padding: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  overflow: hidden;
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}

.testimoal-gallery-moblie {
  position: relative;
}

.testimoal-gallery-moblie-inner-box {
  padding: 1rem 0 2rem 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.testimony-moblie-card {
  position: relative;
  margin-bottom: 1rem;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 10px 40px -17px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 10px 40px -17px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 2rem;
  margin: 0 1rem 0 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 290px;
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}
.testimony-moblie-card .testimony-name {
  margin-top: 0.5rem;
  font-weight: 300;
  font-size: 13px;
  color: #CEAE78;
  text-align: end;
}
.testimony-moblie-card .testimony-box {
  position: relative;
  height: 100%;
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}
.testimony-moblie-card .testimony-box:first-child {
  overflow: scroll;
}
.testimony-moblie-card .testimony-box:first-child .testimony-text {
  width: 100%;
  text-align: left;
  font-style: italic;
}
.testimony-moblie-card .testimony-box:first-child img {
  display: block;
  float: right;
  height: 5rem;
  margin: 0 1rem;
  border-radius: 10px;
}
.testimony-moblie-card .testimony-box:first-child p {
  font-size: 1rem;
}

.audio-gallery-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.audio-gallery {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.footer-container {
  height: 15rem;
  top: 5.5rem;
  background-color: black;
  color: #f4f4f4;
}

.footer-logo {
  width: 20%;
  height: 20%;
}

@media screen and (max-width: 700px) {
  .footer-logo {
    width: 11rem;
  }
}
.info-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  top: 25%;
}
.info-box ul li {
  list-style: none;
  font-size: 1.1rem;
  font-family: "Work Sans", sans-serif;
  font-weight: 300;
}
.info-box .socials a {
  text-decoration: none;
}
.info-box .socials a:active, .info-box .socials a:visited, .info-box .socials a:active {
  text-decoration: none;
}
.info-box .socials svg {
  margin-top: -0.25rem;
  color: #f4f4f4;
  height: 55%;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.info-box .socials svg:hover {
  color: #CEAE78;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

html {
  position: relative;
}

body {
  background-color: #F6F0EA;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
body h1, body h2 {
  font-family: "JetBrains Mono", monospace;
  font-weight: 500;
}
body p {
  font-family: "Work Sans", sans-serif;
  font-weight: 300;
  font-size: 1.35rem;
}

body::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 900px) {
  .contact-box {
    display: none;
  }
  .contact-card {
    margin: 0 auto;
    max-width: 20rem;
  }
  .contact-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
@media screen and (max-width: 700px) {
  .container {
    padding: 0 1rem;
  }
  .bg-video {
    display: block;
  }
  .hero-about-card {
    margin: 0;
    top: 0;
    left: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 25%;
  }
  .hero-about {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    margin: 3rem 0;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .hero-about p {
    display: none;
  }
  .hero-about ul {
    line-height: 1.75rem;
    display: block;
    list-style: none;
    font-family: "Work Sans", sans-serif;
    font-weight: 300;
  }
  .hero-about ul li::before {
    content: "•";
    display: inline-block;
    width: 0.75em;
    margin-left: -1em;
    color: #CEAE78;
  }
  .references-container p {
    width: 100%;
  }
  .references-container button {
    display: none;
  }
  .audio-player-card {
    width: 290px;
  }
  .audio-player-card .audio-player {
    width: 100%;
  }
  .audio-player-card img {
    display: none;
  }
  .audio-gallery-content {
    margin-left: auto;
    margin-right: auto;
  }
  .audio-gallery {
    position: relative;
    padding: 0;
  }
  .clip-box {
    margin: 0 auto;
    overflow-x: auto;
    scroll-behavior: smooth;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .contact-box {
    display: none;
  }
  .contact-card {
    margin: 0 auto;
    max-width: 20rem;
  }
  .contact-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}